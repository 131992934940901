import { FormikErrors } from "formik";
import { Dispatch, SetStateAction, useEffect } from "react";
import { autocompleteOrganizations } from "../../api";

const SCROLL_DELAY = 10;

export const useScrollToFirstError = (
  formErrors: FormikErrors<any> | undefined,
  submit: boolean,
  setSubmit: Dispatch<SetStateAction<boolean>>
) => {
  useEffect(() => {
    if (formErrors && Object.keys(formErrors).length > 0 && submit) {
      const timeout = setTimeout(() => {
        const content = document.getElementById("sidebar-wide-view");
        const errorElements: HTMLCollectionOf<Element> =
          document.getElementsByClassName("border-red-500");
        if (content && errorElements.length > 0) {
          const t =
            content.scrollTop +
            (errorElements[0] as HTMLElement).getBoundingClientRect().top -
            (errorElements[0] as HTMLElement).getBoundingClientRect().height -
            55;
          content.scrollTo({
            top: t,
            behavior: "smooth",
          });
          setSubmit(false);
        }
      }, SCROLL_DELAY);
      return () => clearTimeout(timeout);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [submit]);
};

export const handleAttendees = (
  prevAttendees: any[],
  value: any[],
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void,
  organizations: any[]
) => {
  if (prevAttendees.length > value.length) {
    // remove attendee
    const attendeesOrganizations: string[] = value.map(
      (vl: any) => vl.organization
    );
    const attendeesOrganizationsBeforeDeleted: string[] = prevAttendees.map(
      (vl) => vl.organization
    );

    if (
      attendeesOrganizationsBeforeDeleted.length > attendeesOrganizations.length
    ) {
      const deletedAttendeesOrganization =
        attendeesOrganizationsBeforeDeleted.filter(
          (item) => !attendeesOrganizations.includes(item)
        )[0];
      const orgs = organizations.filter(
        (item) => item.label != deletedAttendeesOrganization
      );
      setFieldValue("organizations", orgs);
    }
  } else if (prevAttendees.length < value.length) {
    // add attendee
    const selectedOrganizations: string[] = organizations?.map(
      (org) => org.label
    );
    const attendeesOrganizations: string[] = value.map((vl) => vl.organization);

    attendeesOrganizations.forEach((attendeesOrganization) => {
      if (!selectedOrganizations.includes(attendeesOrganization)) {
        autocompleteOrganizations(attendeesOrganization).then((response) => {
          if (response?.length) {
            setFieldValue("organizations", [...organizations, response[0]]);
          }
        });
      }
    });
  }
  return value;
};
